import React from 'react'
import { Invitation } from '../../types'
import { Box, FlexRow, IconButtonPrimary, UserAvatar, CheckIcon, Collapse } from '@papertrail/styleguide'
import { useAcceptMemberInvitation, useAcceptOwnerInvitation } from './hooks.invitation'
import { Tracking } from '@papertrail/web3-utils'
import { Trans, useTranslation } from 'react-i18next'

type Props = {
  invitation: Invitation
}

export default function InvitationItem(props: Props) {
  const { invitation } = props

  const [acceptMemberState, acceptMember] = useAcceptMemberInvitation()
  const [acceptOwnerState, acceptOwner] = useAcceptOwnerInvitation()

  const { t } = useTranslation('invitations')

  function acceptInvitation() {
    if (invitation.type === 'member') {
      Tracking.trackEvent('accept_invitation', { type: 'member' })
      acceptMember(invitation.id)
    } else {
      Tracking.trackEvent('accept_invitation', { type: 'owner' })
      acceptOwner(invitation.id)
    }
  }

  function showInvitation() {
    return !acceptMemberState.isLoaded && !acceptOwnerState.isLoaded
  }

  function getInvitationText() {
    if (invitation.type === 'member') {
      return (
        <Trans
          t={t}
          i18nKey="memberInvite"
          defaults="<strong>{{fullName}}</strong> invited you to be a member of account <strong>{{accountName}}</strong>"
          values={{
            fullName: invitation.user.fullName,
            accountName: invitation.account.name
          }}
        />
      )
    } else if (invitation.type === 'owner') {
      return (
        <Trans
          t={t}
          i18nKey="ownerInvite"
          defaults="<strong>{{fullName}}</strong> invited you to be an owner of subscription <strong>{{subscriptionName}}</strong>"
          values={{
            fullName: invitation.user.fullName,
            subscriptionName: invitation.subscription.name
          }}
        />
      )
    }
  }

  return (
    <Collapse in={showInvitation()}>
      <Box p={2} sx={{ borderTop: 1, borderColor: 'grey.300' }}>
        <FlexRow justifyContent={'space-between'}>
          <UserAvatar avatarDetails={invitation.user} size="small" />
          <Box px={2} flexGrow={1} maxWidth={'300px'}>
            {getInvitationText()}
          </Box>
          <Box>
            <IconButtonPrimary
              onClick={acceptInvitation}
              icon={<CheckIcon fontSize={'small'} data-testid="accept-invite" />}
            />
          </Box>
        </FlexRow>
      </Box>
    </Collapse>
  )
}
