import React, { useEffect } from 'react'
import { Box, WhatsNewIcon } from '@papertrail/styleguide'
import { useTranslation } from 'react-i18next'

export default function WhatsNew() {
  let count = 0

  const { t } = useTranslation(['header'])

  function initHeadway() {
    const element = window.document.getElementsByClassName('pt-updates').length

    if (element && (window as any).Headway && typeof (window as any).Headway.init === 'function') {
      ;(window as any).Headway.init({
        selector: '.pt-updates',
        account: 'a7z8ky',
        trigger: '.pt-updates-trigger',
        remoteOptions: {
          //softHide: true,
          eyecatcher: false
        }
      })
    } else if (count <= 3) {
      //this loop copied from original weclient - assume on slow connections headway
      // lib may not be available initially
      window.setTimeout(function () {
        count = count + 1

        initHeadway()
      }, 1000)
    }
  }

  useEffect(() => {
    //initial pause ensures that the custom css is loaded so the indicator looks right
    window.setTimeout(function () {
      initHeadway()
    }, 1000)
  }, [])

  return (
    <Box flexGrow={0} flexShrink={0} px={{ sm: 0, md: 1 }} className="pt-updates" display={'flex'}>
      <div className="pt-updates-trigger">
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{t('whatsNew')}</Box>
        <WhatsNewIcon fontSize="large" sx={{ display: { xs: 'block', sm: 'none' } }} />
      </div>
    </Box>
  )
}
