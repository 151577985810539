import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { I18nextProvider, useTranslation } from 'react-i18next'
import { FlexRow, Loading, theme, ThemeProvider, useMediaQuery } from '@papertrail/styleguide'

import HeaderTitle from './components/HeaderTitle'
import UserMenu from './components/user-menu/UserMenu'
import WhatsNew from './components/WhatsNew'
import UpgradeButton from './components/UpgradeButton'
import NotificationsMenu from './components/notifications/NotificationsMenu'
import './App.css'

import { useSessionAccount, useSessionUser, useSessionMember } from '@papertrail/web3-session'

/**
 * Responsible for loading the account if we are in one, and composing the header out of sub-components.
 * @constructor
 */
export function Header() {
  const user = useSessionUser()
  const account = useSessionAccount()
  const member = useSessionMember()

  const isMobile = useMediaQuery('(max-width:600px)')

  const params = new URLSearchParams(location.search)
  const lang = params.get('lang')
  const { i18n } = useTranslation('global')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  function isTrial() {
    return account && account.isTrial
  }

  function isOwner() {
    return member && member.isAdmin
  }

  return (
    <header id="main-header">
      <FlexRow
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ width: '100%', height: { xs: '56px', sm: '64px' } }}>
        <HeaderTitle account={account} />
        <WhatsNew />
        {!isMobile && isOwner() && isTrial() && <UpgradeButton />}
        <NotificationsMenu user={user} />
        <UserMenu user={user} />
      </FlexRow>
      {isMobile && isOwner() && isTrial() && (
        <FlexRow
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ width: '100%', height: { xs: '56px', sm: '64px' } }}>
          <UpgradeButton />
        </FlexRow>
      )}
    </header>
  )
}

//These props are supplied by the single-spa framework
type Props = {
  i18n: any
}

export default function App(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={props.i18n}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Header />
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  )
}
