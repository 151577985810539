import React, { useEffect } from 'react'
import { Box, Loading } from '@papertrail/styleguide'
import { EventManager } from '@papertrail/web3-utils'
import type { User } from '@papertrail/types'
import { usePendingInvitationsGetApi } from './hooks.invitation'
import InvitationItem from './InvitationItem'
import { useTranslation } from 'react-i18next'

type Props = {
  user: User
}

export default function InvitationsList(props: Props) {
  const { user } = props
  const [invitationsState, loadInvitations] = usePendingInvitationsGetApi()
  const { t } = useTranslation(['invitations'])
  useEffect(() => {
    if (showInvitations()) {
      loadInvitations()
    }
  }, [user])

  useEffect(() => {
    const invEventRef = EventManager.subscribe(['invitation_was_created', 'invitation_was_accepted'], (data: any) => {
      loadInvitations()
    })

    return () => {
      //unsubscribe when unmount
      EventManager.unsubscribe(invEventRef)
    }
  }, [])

  function showInvitations() {
    return user && user.emailConfirmed
  }

  return (
    <Box>
      <Box px={2} py={1}>
        {t('invitations')}
      </Box>
      {!showInvitations() && (
        <Box p={2} sx={{ borderTop: 1, borderColor: 'grey.300' }}>
          {t('pleaseConfirmEmail')}
        </Box>
      )}
      {showInvitations() && !invitationsState.isLoaded && <Loading />}
      {showInvitations() &&
        invitationsState.isLoaded &&
        invitationsState.data.map((inv) => <InvitationItem key={inv.id} invitation={inv} />)}
      {showInvitations() && invitationsState.isLoaded && invitationsState.data.length == 0 && (
        <Box p={2} textAlign="center" sx={{ borderTop: 1, borderColor: 'grey.300' }}>
          {t('noUnreadInvitations')}
        </Box>
      )}
    </Box>
  )
}
