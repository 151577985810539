import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Box, FlexRow, Heading3, Chip, TagIcon, ToolTip } from '@papertrail/styleguide'
import { Account, Partner } from '@papertrail/types'
import { PapertrailSession } from '@papertrail/web3-session'
import { formatDuration } from '@papertrail/web3-utils'

type Props = {
  account: Account
}

export default function HeaderTitle(props: Props) {
  const account = props.account

  const partner: Partner = PapertrailSession.partner

  //so we can change the logo on an exact match
  const accountsMatch = useRouteMatch('/accounts')

  function getPeriod(datetime) {
    const dateTimeConfig = PapertrailSession.getDateTimeConfig()
    return formatDuration(datetime, dateTimeConfig, true)
  }

  function renderAccountTitle() {
    return (
      <Link to={`/accounts/${account.id}`}>
        <FlexRow justifyContent={'left'} alignItems={'center'}>
          {account.logo && (
            <Box marginRight={2} width={'48px'} height={'48px'}>
              <img src={account.logo} alt={`${account.name} Logo`} height={'100%'} />
            </Box>
          )}
          <Heading3>{account.name}</Heading3>
          {account.isDemo && (
            <ToolTip title={account.expiresAt && <span>Expires {getPeriod(account.expiresAt)}</span>}>
              <Chip icon={<TagIcon fontSize={'medium'} />} label="Demo" sx={{ marginLeft: 0.5 }} />
            </ToolTip>
          )}
        </FlexRow>
      </Link>
    )
  }

  function renderPapertrail() {
    if (partner.images.publicLogo) {
      return (
        <div>
          <Link to={'/'}>
            <img src={partner.images.publicLogo} height="50px" alt={partner.partnerName + ' Logo'} />
          </Link>
        </div>
      )
    }

    if (accountsMatch) {
      return (
        <div>
          <Link to={'/'}>
            <img src="https://cdn.papertrail.io/images/joined-logo.svg" height="20px" alt="Papertrail.io Logo" />
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={'/'}>
            <img src="https://cdn.papertrail.io/images/giant-writing.svg" height="20px" alt="Papertrail.io Logo" />
          </Link>
        </div>
      )
    }
  }

  return (
    <Box flexGrow={1} flexShrink={1} px={2} sx={{ overflow: 'hidden' }}>
      {account ? renderAccountTitle() : renderPapertrail()}
    </Box>
  )
}
