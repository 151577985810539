import { useApiGet } from '@papertrail/web3-utils'

export type TasksCount = {
  total: number
}

const mapper = (data: any): number => {
  return data.meta.pagination.total
}

export function useTaskCountApi() {
  const [taskState, doGet] = useApiGet<number>(`/tasks`, mapper)

  const loadTasks = (assigneeId, states) => {
    doGet({
      page: 1,
      limit: 1,
      state: states,
      filter: `assignee_id=${assigneeId}`
    })
  }

  return [taskState, loadTasks] as const
}
