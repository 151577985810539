import { useApiGet, useApiPost } from '@papertrail/web3-utils'
import { Invitation } from '../../types'
import { mapUser, mapAccount } from '@papertrail/types'

function mapPendingInvitations(data): Invitation[] {
  return data.data
    .filter((i) => i.accepted_at == null)
    .map((n) => {
      return mapInvitation(n)
    })
}

function mapInvitation(data): Invitation {
  return {
    id: data.id,
    type: data.type,
    user: mapUser(data.user.data),
    account: mapAccount(data.account.data),
    subscription: {
      id: data.subscription.data.id,
      name: data.subscription.data.name
    }
  }
}

/** Get the invitations for the current user */
export const usePendingInvitationsGetApi = () => {
  const [invitationsState, requestInvitations] = useApiGet<Invitation[]>(`/me/invitations`, mapPendingInvitations)

  const loadInvitations = () => {
    requestInvitations({ include: 'account,subscription,user' })
  }

  return [invitationsState, loadInvitations] as const
}

/**
 * Request a new copy of the email confirmation email
 */
export const useRequestEmailPost = () => {
  const [emailRequestState, postEmailRequest] = useApiPost(`/me/actions/send-confirmation`, (data) => data)

  const requestEmail = () => {
    postEmailRequest({})
  }

  return [emailRequestState, requestEmail] as const
}

export const useAcceptOwnerInvitation = () => {
  const [acceptState, postAccept] = useApiPost(`/owners`, (data) => data)

  const acceptInvitation = (invitationId: string) => {
    postAccept({ invitation_id: invitationId })
  }

  return [acceptState, acceptInvitation] as const
}

export const useAcceptMemberInvitation = () => {
  const [acceptState, postAccept] = useApiPost(`/members`, (data) => data)

  const acceptInvitation = (invitationId: string) => {
    postAccept({ invitation_id: invitationId })
  }

  return [acceptState, acceptInvitation] as const
}
