import { Box, Menu, NotificationsIcon, Badge, FlexRow, TextButton, Collapse } from '@papertrail/styleguide'
import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useNotificationsState, useNotificationsReadAllApi } from './hooks.notification'

import type { User } from '@papertrail/types'
import { EventManager, Tracking } from '@papertrail/web3-utils'
import NotificationItem from './NotificationItem'
import EmailConfirmationPanel from './EmailConfirmationPanel'
import InvitationsList from './InvitationsList'
import { useTranslation } from 'react-i18next'

type Props = {
  user: User
}

/**
 * Notification menu shows the latest 5 notifications.
 * If the user clears a notification, it loads another one
 * If a new notification arrives via websocket, it displays at the top and removes an older one from the display.
 * @constructor
 */
export default function NotificationsMenu(props: Props) {
  const { user } = props
  const [invitationCount, setInvitationCount] = useState(0)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const open = Boolean(menuAnchor)
  const history = useHistory()
  const { t } = useTranslation('notifications')

  const [notificationsState, loadNotifications, readNotification, receiveNotification] = useNotificationsState()

  const [notificationsReadAllState, readAllNotifications] = useNotificationsReadAllApi()

  useEffect(() => {
    const listenerRef = EventManager.subscribe(['notification_was_created'], (data: any) => {
      receiveNotification(data.data)
    })
    const invitationRef = EventManager.subscribe(['invitation_was_created'], (data: any) => {
      setInvitationCount((prevState) => prevState + 1)
    })
    const invitationAcceptedRef = EventManager.subscribe(['invitation_was_accepted'], (data: any) => {
      setInvitationCount((prevState) => prevState - 1)
    })

    return () => {
      //unsubscribe when unmount
      EventManager.unsubscribe(listenerRef)
      EventManager.unsubscribe(invitationRef)
      EventManager.unsubscribe(invitationAcceptedRef)
    }
  }, [])

  useEffect(() => {
    if (user) {
      setInvitationCount(user.invitationCount)
    }
  }, [user])

  useEffect(() => {
    loadNotifications()
  }, [])

  useEffect(() => {
    if (notificationsReadAllState.isLoaded) {
      Tracking.trackEvent('read_all_notifications')
      loadNotifications()
    }
  }, [notificationsReadAllState])

  function handleClick(event) {
    if (user) {
      setMenuAnchor(event.currentTarget)
    }
  }

  function closeMenu() {
    setMenuAnchor(null)
  }

  function goto(route: string) {
    history.push(route)
    closeMenu()
  }

  function hasUnreadNotifications() {
    return notificationsState.isLoaded && notificationsState.data.totalCount > 0
  }

  function getBadgeCount() {
    return (notificationsState.isLoaded ? notificationsState.data.totalCount : 0) + invitationCount
  }

  return (
    <Box flexGrow={0} px={{ xs: 1, sm: 1 }}>
      <Badge badgeContent={getBadgeCount()} variant="dot" color="error">
        <NotificationsIcon fontSize="large" onClick={handleClick} sx={{ cursor: 'pointer' }} />
      </Badge>
      <Menu
        id="notifications-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'right'
        }}
        anchorEl={menuAnchor}
        open={open}
        onClose={closeMenu}>
        {user && !user.emailConfirmed && <EmailConfirmationPanel />}

        {invitationCount > 0 && <InvitationsList user={user} />}

        <Box px={2} py={1}>
          <FlexRow justifyContent={'space-between'} sx={{ width: '100%' }}>
            <Box>{t('notifications')}</Box>
            {hasUnreadNotifications() && (
              <>
                <Box flexGrow={1} px={0.5}>
                  ({t('unread', { count: notificationsState.data.totalCount })})
                </Box>
                <TextButton onClick={readAllNotifications}>{t('markAllRead')}</TextButton>
              </>
            )}
          </FlexRow>
        </Box>

        {!hasUnreadNotifications() && (
          <Box p={2} textAlign="center" sx={{ borderTop: 1, borderColor: 'grey.300' }}>
            {t('noUnreadNotifications')}
          </Box>
        )}
        {user &&
          notificationsState.isLoaded &&
          notificationsState.data.latestNotifications.map((n, index) => {
            return (
              <Collapse key={n.id} in={index < 5 && !notificationsReadAllState.isLoading}>
                <NotificationItem
                  user={user}
                  notification={n}
                  onRead={() => readNotification(n)}
                  closeMenu={closeMenu}
                />
              </Collapse>
            )
          })}
        <Box paddingTop={1} textAlign="center" sx={{ borderTop: 1, borderColor: 'grey.300' }} width="400px">
          <TextButton onClick={() => goto('/notifications')}>{t('viewAll')}</TextButton>
        </Box>
      </Menu>
    </Box>
  )
}
