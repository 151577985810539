import { FetchState, useApiGet, useApiPatch, useApiPost } from '@papertrail/web3-utils'
import { NotificationSummary, PTNotification } from '../../types'
import { mapUser } from '@papertrail/types'
import { useEffect, useReducer } from 'react'

function mapNotifications(data): NotificationSummary {
  const notifications = data.data.map((n) => {
    return mapNotification(n)
  })
  return {
    latestNotifications: notifications,
    totalCount: data.meta.total_unread
  }
}

function mapNotification(data): PTNotification {
  return {
    id: data.id,
    user: data.meta.user ? mapUser(data.meta.user) : null,
    type: data.type,
    createdAt: data.created_at,
    records: data.meta.records,
    recordName: data.meta.record ? data.meta.record.name : null,
    taskTitle: data.meta.task ? data.meta.task.title : null,
    to: data.meta.to
  }
}

const initialNotificationState: FetchState<NotificationSummary> = {
  isLoaded: false,
  isLoading: false,
  isError: false
}

type NotificationAction =
  | { type: 'NOTIFICATIONS_RESET' }
  | { type: 'NOTIFICATIONS_FETCHED'; fetchState: FetchState<NotificationSummary> }
  | { type: 'NOTIFICATION_RECEIVE'; notification: PTNotification }
  | { type: 'NOTIFICATION_READ'; id: string }

function notificationsReducer(
  state: FetchState<NotificationSummary>,
  action: NotificationAction
): FetchState<NotificationSummary> {
  switch (action.type) {
    case 'NOTIFICATIONS_RESET':
      return initialNotificationState
    case 'NOTIFICATIONS_FETCHED':
      if (state.isLoaded) {
        //append new notifications, if they are not already in the state
        if (
          action.fetchState.isLoaded &&
          action.fetchState.data.latestNotifications.length > 0 &&
          state.data.latestNotifications.indexOf(action.fetchState.data.latestNotifications[0]) === -1
        ) {
          return {
            isLoaded: true,
            isLoading: false,
            isError: false,
            data: {
              totalCount: action.fetchState.data.totalCount,
              latestNotifications: [...state.data.latestNotifications, ...action.fetchState.data.latestNotifications]
            }
          }
        } else {
          return state
        }
      } else {
        //first fetch so just return it
        return action.fetchState
      }
    case 'NOTIFICATION_READ':
      return {
        isLoaded: true,
        isLoading: false,
        isError: false,
        data: {
          totalCount: state.data.totalCount - 1,
          latestNotifications: [...state.data.latestNotifications.filter((item) => item.id !== action.id)]
        }
      }
    case 'NOTIFICATION_RECEIVE':
      return {
        isLoaded: true,
        isLoading: false,
        isError: false,
        data: {
          totalCount: state.data.totalCount + 1,
          latestNotifications: [action.notification, ...state.data.latestNotifications]
        }
      }
  }
  return state
}

/* Manage state for the notifications list
 * Has methods for removing read items from list and requesting next unread item */
export const useNotificationsState = () => {
  const notificationTypes =
    'state_was_changed,task_assignee_was_added,task_deadline_was_updated,task_note_was_created,task_upload_was_created,task_upload_was_deleted,task_state_was_updated,task_was_overdue,account_records_retirement_overdue,record_retirement_overdue,partnerhub_partnership_was_created,partnerhub_partnership_was_updated_by_client,partnerhub_partnership_was_accepted_by_client'

  const [notificationsFetchState, fetchNotifications] = useApiGet<NotificationSummary>(
    `/me/notifications`,
    mapNotifications
  )

  const [notificationsState, dispatch] = useReducer(notificationsReducer, initialNotificationState)

  const loadNotifications = () => {
    dispatch({ type: 'NOTIFICATIONS_RESET' })
    fetchNotifications({
      read: false,
      limit: 5,
      cursor: 0,
      type: notificationTypes
    })
  }
  /*
    Remove the read notification and request the next one
   */
  const readNotification = (notification: PTNotification) => {
    const getMore: boolean = notificationsState.data.latestNotifications.length <= 5

    dispatch({ type: 'NOTIFICATION_READ', id: notification.id })

    if (getMore) {
      fetchNotifications({
        read: false,
        limit: 1,
        cursor: 4,
        type: notificationTypes
      })
    }
  }

  const receiveNotification = (data) => {
    const notification = mapNotification(data)

    dispatch({ type: 'NOTIFICATION_RECEIVE', notification: notification })
  }

  useEffect(() => {
    dispatch({ type: 'NOTIFICATIONS_FETCHED', fetchState: notificationsFetchState })
  }, [notificationsFetchState])

  return [notificationsState, loadNotifications, readNotification, receiveNotification] as const
}

/** Mark a single notification as read */
export const useNotificationReadApi = (notificationId: string) => {
  const [readState, postRead] = useApiPatch(`/me/notifications/${notificationId}`, (data) => data)

  const markRead = () => {
    postRead({ read: true })
  }

  return [readState, markRead] as const
}

/** Mark all notifications as read */
export const useNotificationsReadAllApi = () => {
  const [readState, postRead] = useApiPost(`/me/notifications/actions/read`, (data) => data)

  const markRead = () => {
    postRead({ read: true })
  }

  return [readState, markRead] as const
}
