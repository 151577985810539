import React, { useEffect } from 'react'
import { PTNotification } from '../../types'
import { User } from '@papertrail/types'
import { Box, FlexRow, IconButtonPrimary, ParaSmall, UserAvatar, CheckIcon, Collapse } from '@papertrail/styleguide'
import { useNotificationReadApi } from './hooks.notification'
import { getNotificationText } from './utils'
import { formatDateTime, Tracking } from '@papertrail/web3-utils'
import { useHistory } from 'react-router-dom'

type Props = {
  user: User
  notification: PTNotification
  onRead: () => void
  closeMenu: () => void
}

export default function NotificationItem(props: Props) {
  const { notification, closeMenu, onRead, user } = props
  const history = useHistory()

  const [readState, markRead] = useNotificationReadApi(notification.id)

  useEffect(() => {
    if (readState.isLoaded) {
      Tracking.trackEvent('read_notification')
      onRead()
    }
  }, [readState])

  function viewNotification() {
    closeMenu()
    history.push(`/notifications/notification/${notification.id}`)
  }

  return (
    <Collapse in={!readState.isLoaded && !readState.isLoading}>
      <Box p={2} sx={{ borderTop: 1, borderColor: 'grey.300' }}>
        <FlexRow justifyContent={'space-between'}>
          {notification.user && <UserAvatar avatarDetails={notification.user} size="small" />}

          <Box px={2} flexGrow={1} maxWidth={'300px'} onClick={() => viewNotification()} sx={{ cursor: 'pointer' }}>
            {getNotificationText(notification)}
            <ParaSmall>{formatDateTime(notification.createdAt, user.datetimeConfig, true)}</ParaSmall>
          </Box>
          <Box>
            <IconButtonPrimary
              onClick={() => markRead()}
              icon={<CheckIcon fontSize={'small'} />}
              data-testid="read-notification"
            />
          </Box>
        </FlexRow>
      </Box>
    </Collapse>
  )
}
