import { PTNotification } from '../../types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export const getNotificationText = (notification: PTNotification) => {
  const { t } = useTranslation('notifications')

  switch (notification.type) {
    case 'state_was_changed':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="stateWasChanged"
            values={{
              fullName: notification.user.fullName,
              recordName: notification.recordName,
              stateName: notification.to.name
            }}
          />
        </span>
      )
    case 'record_retirement_overdue':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="recordRetirementOverdue"
            values={{
              recordName: notification.recordName
            }}
          />
        </span>
      )
    case 'account_records_retirement_overdue':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="accountRecordsRetirementOverdue"
            values={{
              records: notification.records
            }}
          />
        </span>
      )
    case 'task_assignee_was_added':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskAssigneeWasAdded"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'task_deadline_was_updated':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskDeadlineWasUpdated"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'task_note_was_created':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskNoteWasCreated"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'task_upload_was_created':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskUploadWasCreated"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'task_upload_was_deleted':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskUploadWasDeleted"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'task_state_was_updated':
      return (
        <span>
          <Trans
            t={t}
            i18nKey="taskStateWasUpdated"
            values={{
              fullName: notification.user.fullName,
              taskTitle: notification.taskTitle
            }}
          />
        </span>
      )
    case 'partnerhub_partnership_was_created':
      return <span>You have a new partner invitation in PartnerHub</span>
    case 'partnerhub_partnership_was_accepted_by_client':
      return <span>You have a new partner in PartnerHub</span>
    case 'partnerhub_partnership_was_updated_by_client':
      return <span>A partnership invitation has been updated by a client in PartnerHub</span>

    default:
      return <span>default</span>
  }
}
