import React from 'react'
import { Box, ButtonPrimary } from '@papertrail/styleguide'
import { useTranslation } from 'react-i18next'

/**
 * @param props
 * @constructor
 */
export default function UpgradeButton() {
  const { t } = useTranslation(['header'])

  function onClick() {
    window.open('https://www.papertrail.io/contact-papertrail')
  }

  return (
    <Box flexGrow={0} flexShrink={0} px={1}>
      <ButtonPrimary onClick={onClick}>{t('upgrade')}</ButtonPrimary>
    </Box>
  )
}
