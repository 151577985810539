import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { User } from '@papertrail/types'
import { EventManager } from '@papertrail/web3-utils'
import { useTaskCountApi } from './hooks'
import { useTranslation } from 'react-i18next'

import {
  Box,
  HelpIcon,
  LogoutIcon,
  Menu,
  MenuItem,
  ProfileIcon,
  TasksIcon,
  AccountsIcon,
  ProductImportsIcon,
  UserAvatar,
  Badge
} from '@papertrail/styleguide'

type Props = {
  user: User
}

export default function UserMenu(props: Props) {
  const { user } = props
  const history = useHistory()
  const { t } = useTranslation(['header'])
  const [tasksCountState, loadTaskCount] = useTaskCountApi()
  const [userMenuAnchor, setUserMenuAnchor] = useState(null)
  const open = Boolean(userMenuAnchor)

  function getAllNonClosedTaskStates() {
    return 'open,pending'
  }

  useEffect(() => {
    if (user) {
      loadTaskCount(user.id, getAllNonClosedTaskStates())
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const listenerRef = EventManager.subscribe(
        ['task_assignee_was_added', 'task_assignee_was_removed', 'task_state_was_updated'],
        () => {
          loadTaskCount(user.id, getAllNonClosedTaskStates())
        }
      )
      return () => {
        // unsubscribe when unmount
        EventManager.unsubscribe(listenerRef)
      }
    }
  }, [user])

  function handleClick(event) {
    setUserMenuAnchor(event.currentTarget)
  }

  function closeMenu() {
    setUserMenuAnchor(null)
  }

  function goto(route: string) {
    history.push(route)
    closeMenu()
  }

  function gotoHelpCentre() {
    window.open('https://help.papertrail.io')
    closeMenu()
  }

  return (
    <Box flexGrow={0} flexShrink={0} px={{ xs: 1, md: 2 }}>
      <UserAvatar avatarDetails={user} onClick={handleClick} size="medium" />
      <Menu id="user-menu" anchorEl={userMenuAnchor} open={open} onClose={closeMenu}>
        <MenuItem onClick={() => goto('/profile/basic')}>
          <ProfileIcon sx={{ marginRight: 1 }} /> Profile
        </MenuItem>
        <MenuItem onClick={() => goto('/tasks?state=open,pending')}>
          <TasksIcon sx={{ marginRight: 1 }} /> Tasks
          {tasksCountState.isLoaded && (
            <Badge
              data-testid="task-count"
              color="primary"
              badgeContent={tasksCountState.data}
              sx={{ marginLeft: 2 }}
            />
          )}
        </MenuItem>
        <MenuItem onClick={() => goto('/imports')}>
          <ProductImportsIcon sx={{ marginRight: 1 }} />
          Product Imports
        </MenuItem>
        <MenuItem onClick={() => goto('/accounts')}>
          <AccountsIcon sx={{ marginRight: 1 }} />
          Accounts/Subscriptions
        </MenuItem>
        <MenuItem onClick={gotoHelpCentre}>
          <HelpIcon sx={{ marginRight: 1 }} />
          {t('helpCentre')}
        </MenuItem>
        <MenuItem data-testid="logout-menu-item" onClick={() => goto('/logout')}>
          <LogoutIcon sx={{ marginRight: 1 }} />
          {t('logout')}
        </MenuItem>
      </Menu>
    </Box>
  )
}
