import React from 'react'
import { ColourBox, TextButton } from '@papertrail/styleguide'
import { useRequestEmailPost } from './hooks.invitation'
import { useTranslation } from 'react-i18next'

export default function EmailConfirmationPanel() {
  const [emailRequestState, requestEmail] = useRequestEmailPost()
  const { t } = useTranslation('header')
  return (
    <ColourBox px={2} py={1} severity={'warning'}>
      {t('pleaseValidateEmail')}
      <br />
      <TextButton onClick={requestEmail} disabled={emailRequestState.isLoaded}>
        {emailRequestState.isLoaded ? <>{t('emailSent')}</> : <>{t('resendEmail')}</>}
      </TextButton>
    </ColourBox>
  )
}
